<template>
  <el-menu :default-active="activeMenu" router class="el-menu-vertical-demo ptb-32" background-color="#FFFFFF"
    text-color="#8490AD" active-text-color="#2974EF">
    <template v-for="(nav, navInd) in navs">
      <el-submenu v-if="nav.children && nav.children.length" v-show="($isPc ? !nav.isWap : !nav.isPc)"
        :key="nav.route_url" :index="nav.route_url">
        <template slot="title">{{ nav.name }}</template>
        <el-menu-item v-for="(item, index) in nav.children" :key="index" :index="item.route_url">
          {{ item.name }}
        </el-menu-item>
      </el-submenu>
      <el-menu-item v-else :key="navInd" :index="nav.route_url"
        v-show="($isPc ? !nav.isWap : !nav.isPc) && ($store.getters.userToken ? true : !nav.watchLogin)"
        @click="changeNav(nav)">
        <span> {{ nav.name }}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>
<script>
import { logout } from '@/api/account'
export default {
  name: 'MyMenu',
  data () {
    return {
      navs: [
        { name: '我的订单', route_url: '/my/order/finance', watchLogin: true, isWap: true },
        {
          name: '我的订单',
          route_url: '/my/order',
          watchLogin: true,
          isPc: true,
          children: [
            { name: '质押融资', route_url: '/my/order/finance' },
            { name: '知产交易', route_url: '/my/order/trading' }
          ],
        },
        {
          name: '交易管理',
          route_url: '/my/trading',
          watchLogin: true,
          isPc: true,
          children: [
            { name: '卖出管理', route_url: '/my/trading/sell' },
            { name: '买入管理', route_url: '/my/trading/buy' }
          ],
        },
        { name: '联系我们', route_url: '/counsel', isWap: true, watchLogin: false },
        { name: '退出登录', event: 'logout', isWap: true, watchLogin: true },
      ]
    };
  },
  computed: {
    activeMenu () {
      const route = this.$route
      const { meta, path } = route
      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
  },
  methods: {
    changeNav (nav) {
      if (nav.event == 'logout') {
        this.logout()
      }
    },
    async logout () {
      await logout()
      this.$store.dispatch('setUserToken', '')
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-submenu {

  /* 文字 */
  &.is-active {

    .el-submenu__title {
      color: rgb(52, 126, 237) !important;
    }
  }

  .el-menu-item {
    min-width: auto;
    padding-left: 80px !important;
    text-align: left;
  }
}
</style>