<template>
  <div class="my-info">
    <div class="user-info back-fff mb-20">
      <template v-if="userToken">
        <el-avatar class="mb-10" :src="require('@/assets/images/avatar.png')"></el-avatar>
        <p>{{ userInfo.username }}</p>
      </template>
      <template v-else>
        <el-avatar class="mb-10" :src="require('@/assets/images/avatar_default.png')"> </el-avatar>
        <el-button type="primary" size="mini">
          <router-link to="/account/login">请登录</router-link>
        </el-button>
      </template>
    </div>
    <MyMenu />
  </div>
</template>
<script>

import { mapGetters } from 'vuex'
import MyMenu from './menu.vue'
export default {
  name: 'My',
  components: {
    MyMenu
  },
  computed: {
    ...mapGetters([
      'userToken',
      'userInfo'
    ])
  }
}
</script>
<style lang="scss" scoped>
.my-info {
  min-height: 100%;
  background-color: #f4f6fa;
}

.user-info {
  text-align: center;
  padding: 50px 0;
}

::v-deep.el-menu {

  .el-menu-item,
  .el-submenu .el-submenu__title {
    text-align: left;
    border-bottom: 1px solid #e3e3e3;
  }
}


.el-button {
  display: block;
  margin: 0 auto;

  a {
    color: #fff;
  }
}
</style>